import Prompt from "./chat/Prompt";
import History from "./chat/History";
import { useLocation, useParams } from "react-router-dom";
import Title from "./chat/Title";
import Separator from "components/Separator";
import { useRef } from "react";
import { useIsArchivedThread } from "state/api/threads";

export default function Chat(threadId) {
    const { threadId: tId } = useParams();
    const id = tId || threadId;
    const location = useLocation();
    const template = location.state;

    const editorRef = useRef();
    const { data: isArchived } = useIsArchivedThread(id);

    return <div className="mx-auto max-w-7xl lg:p-2 justify-start flex flex-col items-stretch h-full max-h-full relative">
        <Title uuid={id} />
        <Separator className="mb-2" />
        <History uuid={id} editorRef={editorRef} />
        {!isArchived && <Prompt uuid={id} initialPrompt={template} editorRef={editorRef} />}
    </div>
}
