import { unified } from "unified";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import rehypeStringify from "rehype-stringify";
import remarkGridTables from "remark-grid-tables";
import rehypeReact from "rehype-react";
import React from "react";
import rehypeRaw from "rehype-raw";
import * as prod from 'react/jsx-runtime'

import { rehype } from 'rehype';
import MarkdownImage from "./MarkdownImage";
import MarkdownCode from "./MarkdownCode";
import ReactQuote from "components/lexical/nodes/quote/ReactQuote";

// TODO: handle multiline <p> in table items
function sanitizeGridTables(theString) {
    if (!theString)
        return theString;
    let newString = "";
    let inTable = false;
    let lastHorizontal = false;
    let firstLine = true;
    for (const line of theString.split("\n")) {
        if (firstLine && line.trim().startsWith("[") && line.trim().endsWith("]")) {
            // this is to separate [ DOC TITLE: ... ] from the remainder of the body
            newString += line + "\n\n";
        } else {
            if (!inTable) {
                // if you're not in a table and meet a nice
                // +---------------+ line: whack! That's a table!
                if (line.trim().startsWith("+-") && line.trim().endsWith("-+")) {
                    inTable = true;
                    lastHorizontal = true;
                    // detach, so that it can be parsed properly by remark
                    newString += "\n";
                }
            } else {
                // check if you're still in a table
                const isHorizontal =
                    (line.trim().startsWith("+-") || line.trim().startsWith("+ ")) &&
                    (line.trim().endsWith("-+") || line.trim().endsWith(" +"));
                if (
                    !(
                        (line.trim().startsWith("|") && line.trim().endsWith("|")) ||
                        (line.trim().startsWith("+=") && line.trim().endsWith("=+")) ||
                        (!lastHorizontal && isHorizontal)
                    )
                ) {
                    // previous table is ended, but you could be right into
                    // the next table!
                    inTable = isHorizontal;
                    // detach, so that it can be parsed properly by remark
                    newString += "\n";
                }
                lastHorizontal = isHorizontal;
            }
            newString += line + "\n";
        }
        firstLine = false;
    }
    return newString;
}

// Funzione per convertire HTML in componenti React
export function convertHtmlToReact(htmlString, isSource) {
    // Creazione del processore Rehype
    const processor = rehype().use(rehypeReact, {
        createElement: React.createElement,
        Fragment: prod.Fragment,
        jsx: prod.jsx,
        jsxs: prod.jsxs,
        passNode: true,
        components: {
            // Associazione di elementi HTML a componenti React personalizzati
            img: (props) => <MarkdownImage src={props.src} alt={props.src} isSource={isSource} />,
            p: ({ children }) => <div>{children}</div>,
            prog: () => <></>,
            clear: () => <></>,
            code: (props) => <MarkdownCode {...props} />,
            h1: ({ children }) => <div className="font-semibold text-lg mt-3 first:mt-1">{children}</div>,
            h2: ({ children }) => <div className="font-bold text-xl mt-3 first:mt-1">{children}</div>,
            blockquote: ({ children }) => <ReactQuote content={children} />,
            comment: <></>
        },
    });

    // Parsing dell'HTML in un AST (Abstract Syntax Tree)
    //console.log("HTML", htmlString);
    const k = processor.parse(htmlString);
    //console.log("parsed", k);
    const tmp = k.children.find((el) => el.tagName === "html").children;
    //console.log("Coglione", tmp);
    //if (tmp === undefined) console.log("CAZZZONw", k);
    const ast = tmp.find((el) => el.tagName === "body");
    ast.tagName = "div";
    // Conversione dell'AST in un componente React
    const react = processor.stringify(ast);
    return react;
}

export async function processMarkdown(md) {
    try {
        const processed = await unified()
            .use(remarkParse)
            .use(remarkGridTables)
            .use(remarkRehype, { allowDangerousHtml: true })
            .use(rehypeRaw)
            .use(rehypeStringify)
            .process(sanitizeGridTables(md));
        return processed.value;
    } catch (e) {
        return e;
    }

}