import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import { useMediaQuery } from "react-responsive";
import { DEFAULT_TITLE, useGetThreadFromUuidQuery, useLazySuggestThreadTitleQuery, useStreamMutation, useUpdateThreadTitleMutation } from "../../../state/api/threads";
import AudioRecorder from "../../AudioRecorder";
import { CheckIcon, MicrophoneIcon, PhotoIcon, XCircleIcon } from "@heroicons/react/24/outline";
import microphoneService from "../../../utils/MicrophoneService";
import { useTranscribeAudioMutation } from "../../../state/api/services";
import Buttoon from "../../Buttoon";
import { useCurrentCollectionId, useCurrentOrganizationId } from "../../../state/GeneralSlice";
import { useGetModelNameQuery, useGetVisionCapableQuery, useIsExcel } from "../../../state/api/collections";
import { $createParagraphNode, $createTextNode, $getRoot, $setSelection, CLEAR_EDITOR_COMMAND } from "lexical";
import AttachImageModal from "./AttachImageModal";
import Editor from "components/lexical/Editor";
import { INSERT_IMAGE_COMMAND } from "components/lexical/nodes/image/ImagePlugin";
import { __build__parseBooleanEnvs } from "../../../utils/Utils";
import classNames from "classnames";
import ApproveThreadModal from "./ApproveThreadModal";

const ERROR_MESSAGE = {
    "": "Server Error... /:",
    "ask_directout": "Quota Exceeded! Contact ask@directout.eu to increase your limit."
}

export default function Prompt({ uuid, initialPrompt, editorRef }) {
    const [isShowingBePatient, setIsShowingBePatient] = useState(false);
    const [isShowingError, setIsShowingError] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const [stream, { isLoading }] = useStreamMutation();
    const orgId = useCurrentOrganizationId();

    const { historyLength, title, refetch } = useGetThreadFromUuidQuery({ thread_uuid: uuid }, {
        selectFromResult: ({ data }) => ({
            historyLength: data?.messages.filter(m => m.sender_type !== "banner").length,
            title: data?.title,
        })
    })
    const [suggestTitle] = useLazySuggestThreadTitleQuery();
    const [updateTitle] = useUpdateThreadTitleMutation();

    const firstAnswer = historyLength === 2;
    useEffect(() => {
        if (firstAnswer && !isLoading && title === DEFAULT_TITLE) {
            async function doStuff() {
                refetch();
                const response = await suggestTitle({ thread_uuid: uuid, current_org_uuid: orgId });
                if (response.data) {
                    updateTitle({ title: response.data, uuid: uuid });
                }
            }
            doStuff();
        }
    }, [firstAnswer, isLoading, orgId, refetch, suggestTitle, title, updateTitle, uuid])

    const warning = useCallback(() => {
        // Popup!
        setIsShowingBePatient(true)
        setTimeout(() => setIsShowingBePatient(false), 3000)
    }, [setIsShowingBePatient])

    const error = useCallback(() => {
        // Popup!
        setIsShowingError(true)
        setTimeout(() => setIsShowingError(false), 5000)
    }, [setIsShowingError])


    const clearEditor = useCallback(() => editorRef.current.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined), [editorRef]);
    const setEditorValue = useCallback((string) => {
        editorRef.current.update(() => {
            const root = $getRoot();
            root.clear();
            const paragraphNode = $createParagraphNode();
            paragraphNode.append($createTextNode(string));
            root.append(paragraphNode);
        })
    }, [editorRef]);

    useEffect(() => {
        if (initialPrompt) setEditorValue(initialPrompt);
    }, [initialPrompt, setEditorValue])

    const [transcribe, { isLoading: isTranscribing }] = useTranscribeAudioMutation();
    const recBlob = useRef();

    const submit = useCallback(async () => {
        if (isRecording) {
            const audiofile = new File([recBlob.current], "audiofile.webm", { type: "audio/webm" });
            setIsRecording(false);
            const response = await transcribe({ params: { current_org_uuid: orgId }, body: { rec: audiofile } });
            if (response.error) {
                error();
            }
            else {
                setEditorValue(response.data.text);
                editorRef.current.update(() => {
                    const root = $getRoot();
                    const selection = root.selectEnd();
                    $setSelection(selection);
                })
            }
            microphoneService.setMicrophoneAvailable(true);
            return;
        }

        const value = editorRef.current.getEditorState().read(() => $getRoot().getTextContent()).trim();
        clearEditor();

        if (value === "")
            return;

        if (isMobile) {
            const inputRef = editorRef.current;
            inputRef.blur();
        }

        const result = await stream({
            thread_uuid: uuid,
            current_org_uuid: orgId,
            payload: {
                question: value
            }
        })

        if (result.error){
            error();
        }
    }, [clearEditor, editorRef, error, isMobile, isRecording, orgId, setEditorValue, stream, transcribe, uuid])

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (isLoading)
            warning();
        else
            await submit();
    }, [isLoading, warning, submit]);

    useEffect(() => {
        return () => microphoneService.setMicrophoneAvailable(true);
    }, [])

    const collId = useCurrentCollectionId();
    const { modelName } = useGetModelNameQuery({ collection_id: collId }, {
        skip: !collId,
        selectFromResult: ({ data }) => ({ modelName: data || "Vicuña" })
    });

    const { data: isVisionCapable } = useGetVisionCapableQuery({ collection_id: collId }, { skip: !collId, refetchOnMountOrArgChange: true });
    const [showAttach, setShowAttach] = useState(false);
    const onAttachImages = useCallback((images) => {
        images.forEach(element => {
            editorRef.current.dispatchCommand(INSERT_IMAGE_COMMAND, {
                text: element.title,
                src: element.src
            });
        });
    }, [editorRef]);

    const [showApproval, setShowApproval] = useState(false);

    const isTTSEnabled = __build__parseBooleanEnvs("REACT_APP_TTS_ENABLED", true);

    const isExcel = useIsExcel();

    const errorMessage = ERROR_MESSAGE[process.env.REACT_APP_CONFIG] ?? ERROR_MESSAGE[""];

    return <div id="chatBar" className="flex-shrink px-4 pt-2 mb-2 xl::mb-0">
        <div className="absolute w-0 h-0">
            <Transition
                show={isShowingBePatient}
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-full opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="translate-y-full opacity-0"
                className={"absolute"}
            >
                <div className={"relative -top-[5rem] w-[20rem] border-l-4 border-orange text-orange p-2 py-2 mb-2 bg-gradient-to-r from-blue-dark from-75% to-transparent to-100%"} role="alert">
                    <p className="font-bold text-orange-pastel">Be Patient!</p>
                    <p className="text-orange-light">Wait for {modelName} to answer.</p>
                </div>
            </Transition>
            <Transition
                show={isShowingError}
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-full opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="translate-y-full opacity-0"
                className={"absolute"}
            >
                <div className={"relative -top-[10rem] w-[20rem] border-l-4 border-orange text-orange p-2 py-2 mb-2 bg-gradient-to-r from-blue-dark from-75% to-transparent to-100%"} role="alert">
                    <p className="font-bold text-orange-pastel">Error</p>
                    <p className="text-orange-light">{errorMessage}</p>
                </div>
            </Transition>
        </div>
        <div className="relative">
            <form onSubmit={handleSubmit} className="w-full flex rounded-md border-0 ring-1 ring-blue-light focus:ring-inset focus:ring-blue-lightest">
                <div className="relative flex items-center w-full min-h-[2.5rem] bg-blue rounded-l-md border-0 focus:ring-0">
                    {!isRecording && <Editor
                        className="w-full bg-blue-dark rounded-l-md border-0 text-white focus:ring-0 outline-none"
                        placeholder={isTranscribing ? "Transcribing..." : "Message"}
                        onEnterPressed={handleSubmit}
                        editorRef={editorRef}
                    />}

                    <Transition
                        show={isRecording}
                        appear={true}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-x-12"
                        enterTo="opacity-100 translate-x-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-x-0"
                        leaveTo="opacity-0 translate-x-12"
                        as="div"
                        className="w-full absolute"
                    >
                        <AudioRecorder
                            isRecording={isRecording}
                            onRecordingStarted={() => setDisabled(true)}
                            onRecordingStopped={(url, blob) => recBlob.current = blob}
                            onLoadedMetadata={(duration) => setDisabled(duration < 1.0)}
                        />
                    </Transition>
                </div>
                <div className="right-0 items-center inset-y-0 flex">
                    {isTTSEnabled && <button type="button"
                        className="inline-flex items-center justify-center h-full px-2 bg-blue-dark transition duration-500 ease-in-out text-blue-lightest hover:bg-blue focus:outline-none"
                    >
                        {isRecording
                            ? <XCircleIcon className="w-6" onClick={() => { setIsRecording(false); microphoneService.setMicrophoneAvailable(true); setDisabled(false); }} />
                            : <MicrophoneIcon className="w-6" onClick={() => { setIsRecording(true); clearEditor(); }} />
                        }
                    </button>
                    }

                    {isVisionCapable &&
                        <button type="button"
                            className="inline-flex items-center justify-center h-full px-2 bg-blue-dark transition duration-500 ease-in-out text-blue-lightest hover:bg-blue focus:outline-none"
                            onClick={() => setShowAttach(true)}
                        >
                            <PhotoIcon className="w-6" />
                        </button>
                    }

                    <Buttoon type="submit"
                        disabled={disabled}
                        classNameOverride={classNames(
                            "inline-flex items-center justify-center h-full px-2 bg-blue-dark transition duration-500 ease-in-out text-blue-lightest hover:bg-blue focus:outline-none",
                            { "rounded-r-md": !isExcel }
                        )}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                            className="h-6 w-6 transform rotate-90">
                            <path
                                d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                        </svg>
                    </Buttoon>

                    {isExcel &&
                        <button type="button"
                            className="inline-flex border-l-[1px] border-blue-light items-center justify-center rounded-r-md h-full px-2 bg-blue-dark transition duration-500 ease-in-out text-blue-lightest hover:bg-green-pastel focus:outline-none"
                            onClick={() => setShowApproval(true)}
                        >
                            <CheckIcon className="w-6" />
                        </button>
                    }
                </div>
            </form>
            {showAttach && <AttachImageModal show={showAttach} setShow={setShowAttach} onSubmit={onAttachImages} />}
            {showApproval && <ApproveThreadModal threadId={uuid} show={showApproval} setShow={setShowApproval} onSubmit={onAttachImages} />}
        </div>
    </div>
}