import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import './App.css';
import Dashboard from "./components/Dashboard";
import accessTokenService from "./utils/AccessTokenService";
import Loading from './components/content/Loading';
import Administration from './components/content/administration/Administration';
import OrganizationDetails from './components/content/administration/OrganizationDetails';
import CollectionsDetails from './components/content/administration/CollectionsDetails';
import Totem from 'components/content/totem/Totem';
import TotemChat from 'components/content/totem/TotemChat';
import { CreateChatAsm, CreateChatProdigyMp } from 'components/content/totem/CreateTotemChat';
import Home from 'components/content/Home';
import CreateChat from 'components/content/chat/CreateChat';
import Chat from 'components/content/Chat';
import KnowledgeBase from 'components/content/KnowledgeBase';
import Agents from 'components/content/Agents';
import Settings from 'components/content/settings/Settings';
import Privileges from 'components/content/privileges/Privileges';
import Feedbacks from 'components/content/feedbacks/Feedbacks';
import FeedbacksChat from 'components/content/feedbacks/FeedbacksChat';
import NoPage from 'components/content/NotFound';
import Users from 'components/content/administration/Users';
import { useEffect } from 'react';
import { loadUserId } from 'utils/Session';
import BudgetDetails from 'components/content/administration/BudgetDetails';
import Homescreen from 'components/content/home/Homescreen';
import DirectOutTotem from 'components/content/dotec/DirectOutTotem';
import DotecChat from 'components/content/dotec/DotecChat';
import { CreateDotecProdigyMcChat, CreateDotecProdigyMpChat, CreateDotecProdigyMxChat } from 'components/content/dotec/CreateDotecChat';
import HeaderProvider from "./components/HeaderProvider";
import Laboratory from "./components/content/experimental/Laboratory";


function getRouterConfiguration() {
    switch (process.env.REACT_APP_CONFIG) {
        case "ask_directout": return (
            <Route path='*'>
                <Route index={true} element={<DirectOutTotem />} />
                <Route path=":threadId" element={<DotecChat />} />
                <Route path="prodigymc" element={<CreateDotecProdigyMcChat />} />
                <Route path="prodigymp" element={<CreateDotecProdigyMpChat />} />
                <Route path="prodigymx" element={<CreateDotecProdigyMxChat />} />
                <Route path="*" element={<NoPage />} />
            </Route>
        )
        default: return (
            <Route path='*'>
                <Route path="admin" >
                    <Route index={true} element={<Administration />} />
                    <Route path=":orgId" >
                        <Route index={true} element={<OrganizationDetails />} />
                        <Route path="collections" element={<CollectionsDetails />} />
                        <Route path="budgets" element={<BudgetDetails />} />
                    </Route>
                </Route>
                {process.env.REACT_APP_TOTEM_ENABLED === "true" &&
                    <Route path="totem" >
                        <Route index={true} element={<Totem />} />
                        <Route path=":threadId" element={<TotemChat />} />
                        <Route path="asm" element={<CreateChatAsm />} />
                        <Route path="prodigymp" element={<CreateChatProdigyMp />} />
                    </Route>
                }
                <Route path="*" element={<Dashboard />} >
                    <Route index={true} element={<Home />} />
                    <Route path="chat" >
                        <Route index={true} element={<Homescreen />} />
                        <Route path="new" element={<CreateChat />} />
                        <Route path=":threadId" element={<Chat />} />
                    </Route>
                    <Route path="knowledgeBase" element={<KnowledgeBase />} />
                    <Route path="agents" element={<Agents />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="privileges" element={<Privileges />} />
                    <Route path="members" element={<Users />} />
                    <Route path="feedbacks" >
                        <Route index={true} element={<Feedbacks />} />
                        <Route path=":threadId" element={<FeedbacksChat />} />
                    </Route>
                    {process.env.REACT_APP_TEST_PAGE_ENABLED === "true" &&
                        <Route path="laboratory" element={<Laboratory />} />
                    }
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Route>
        )
    }
}

const router = createBrowserRouter(createRoutesFromElements(getRouterConfiguration()));

function App() {
    useEffect(() => {
        loadUserId();
    }, []);


    return (
        <HeaderProvider>
            <RouterProvider router={router} />
        </HeaderProvider>
    );
}

export default withAuthenticationRequired((props) => {
    const { getAccessTokenSilently } = useAuth0();
    accessTokenService.setAccessTokenFn(getAccessTokenSilently);

    return <App {...props} />
}, {
    onRedirecting: () => (<Loading />)
});
